import './App.css';
import {
    AppBar,
    Paper,
    Grid,
    Hidden,
    Link,
    ThemeProvider,
    Typography,
    makeStyles,
    createMuiTheme
} from "@material-ui/core";
import logo from './resources/TriplePHeatersTransparent.png';
import heaterImage from './resources/heater1.jpg'

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#1f1f2e',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
    },
});

const useStyles = makeStyles({
    logo: {
        maxWidth: 160,
    },
    siteTitle: {
        fontSize: '4rem',
        textAlign: 'center',
        fontStyle: 'oblique',
        fontWeight: 'bold',
    },
    media: {
        /*[theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('lg')]: {
            height: '500px'
        }*/
        width: '100%'
    },
    title: {
        fontWeight: 'bold',
        textAlign: 'center'
    },
    features: {
        '& li': {
            marginBottom: '12px',
        },
        display: 'inline-flex',
        flexDirection: 'column'
    },
    price: {
        textAlign: 'right'
    },
    contact: {
        marginTop: '12px'
    },
    nobr: {
        whiteSpace: 'nowrap'
    }
});

function App() {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <header className="App-header">
                    <AppBar position="static" color="primary">
                        <Grid container>
                            <Grid item xs={12} md={2}>
                                <img className={classes.logo} src={logo} alt="Home"/>
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={0} md={8}>
                                    <Typography variant={"h3"} className={classes.siteTitle}>Triple-P
                                        Heaters</Typography>
                                </Grid>
                                <Grid item md={2}/>
                            </Hidden>
                        </Grid>
                    </AppBar>
                </header>
                <Grid container justify={"center"}>
                    <Grid item xs={12} sm={10}>
                        <Paper elevation={4}>
                            <Grid container justify={"space-evenly"}>
                                <Grid item xs={12} sm={10} md={8} lg={4}>
                                    <img className={classes.media} src={heaterImage} alt={"Heater"}/>
                                </Grid>
                                <Grid container item xs={12} sm={10} md={8} lg={6} justify={"center"}>
                                    <div className={classes.features}>
                                        <Typography className={classes.title}
                                                    variant={"h4"}>Low-Budget-Heater</Typography>
                                        <Typography variant={"h6"}>
                                            <ul>
                                                <li>Fully custom designed 3D-printed housing</li>
                                                <li>Dimensions(h/l/w): 9.5 x 11 x 4.5 cm (3.75 x 4.35 x 1.75 in)</li>
                                                <li>~4 seconds to heat from 0 to *Click*</li>
                                                <li>Powered by 3 exchangeable 18650 batteries</li>
                                                <li>Voltmeter to display your battery charge level</li>
                                                <li>Diametral magnet to cool down your Dynavap</li>
                                                <li>Momentary switches on both sides (blue buttons)</li>
                                                <li>Green lighted glass when heating</li>
                                                <li>Power switch at the top</li>
                                                <li>Protection cover for transport</li>
                                            </ul>
                                        </Typography>
                                        <Typography className={classes.price} variant={"h4"}>
                                            Price: 75 Euro
                                            <Typography variant={"body1"}>
                                                (without batteries and battery charger)
                                            </Typography>
                                        </Typography>
                                        <Typography className={classes.contact} variant={"h6"}>
                                            Requests can be sent to:<br/>
                                            <Link href={"mailto:contact@triplep-heaters.com"}>contact@triplep&#8209;heaters.com</Link>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    );
}

export default App;
